import React, { useState } from "react"
import { useApp } from "../../../../hooks/useApp"
import { useCore } from "../../../../hooks/useCore"
import { useCheckoutContext } from "../../../../hooks/useCheckout"

export const withSearchProductCard =
  Component =>
  ({
    name = "SearchProductCard",
    product,
    hideQuickView = false,
    collectionsObjForBreadCrumbs = undefined,
    layout = "",
    price,
    isPriceLoaded,
  }) => {
    const {
      config: {
        settings: {
          routes,
          tags: { swatchColor, message: messageTag, badge: badgeTag },
        },
      },
      outOfStockVariants,
    } = useApp()

    const {
      helpers: { handleize },
    } = useCore()

    // Parse the metafields data
    const parseSiblings = () => {
      try {
        const siblingsMetafield = product.metafields?.find(
          metafield => metafield.key === "siblings"
        )
        const parseSiblings = JSON.parse(siblingsMetafield?.value)
        if (parseSiblings) {
          return parseSiblings
        }

        return []
      } catch (error) {
        console.error("Error parsing siblings metafield:", error)
        return []
      }
    }

    const siblings = parseSiblings()

    /**
     * Represents the size filter parameters obtained from the URL search query.
     * If the size filter parameter is not present in the URL, it defaults to an empty array.
     */
    const SizeFilterParams =
      new URLSearchParams(window.location.search).get("size") || "[]"

    /**
     * Filters available products based on selected size in RS
     * @param {Object} product - The product to filter.
     * @returns {boolean} - True if the product meets the criteria, false otherwise.
     */

    // Format the size filter parameters to uppercase
    const formatSizeFilterParams = JSON.parse(SizeFilterParams).map(filter => {
      const splitFilter = filter.split("|")
      return splitFilter.length > 1 ? splitFilter[1].toUpperCase() : ""
    })

    const soldOutVariant =
      formatSizeFilterParams.length > 0 &&
      product?.variants.find(
        variant =>
          formatSizeFilterParams.includes(variant.option2) &&
          outOfStockVariants?.includes(variant?.storefrontId),
      )

    const filterAvailableProducts = product =>
      formatSizeFilterParams.length == 0 ||
      product?.variants?.some(
        variant =>
          formatSizeFilterParams.includes(variant.option2) && variant.available,
      )

    const { giftcardHandle } = useCheckoutContext()

    const isParentProduct = product?.tags?.some(tag => tag === "parent")

    const swatchesOrderList = product?.tags
      ?.filter(tag => tag?.includes("swatch_order"))
      ?.sort((a, b) => a?.split(":")?.[2] - b?.split(":")?.[2])
      ?.map(tag => tag?.split(":")?.[1])

    const productColoursList = Array.from(
      new Set(
        product.variants
          .map(variant => variant.option1)
          .filter(colour => colour !== "Default Title")
          .filter(colour => colour !== "parent")
          .sort((a, b) => {
            let indexOfA = swatchesOrderList.indexOf(a)
            let indexOfB = swatchesOrderList.indexOf(b)
            indexOfA = indexOfA < 0 ? swatchesOrderList.length : indexOfA
            indexOfB = indexOfB < 0 ? swatchesOrderList.length : indexOfB
            return indexOfA - indexOfB
          }),
      ),
    )

    const identifyChildTag = tag => {
      return tag.includes("colours") || tag.includes("x-sibling-handle")
    }

    const getChildProductHandle = colour => {
      const handleizeColour = handleize(colour?.replace("/", "")?.replace("Ltd -", "") || "")

      const tags = (product?.tags ?? []) as string[]
      const childProductTag = tags
        .filter(identifyChildTag)
        .filter(tag => tag.includes(handleizeColour))?.[0]

      return childProductTag?.split(":")?.[1]
    }

    const products = productColoursList
      .map(colour => ({
        title: product.title,
        handle: isParentProduct
          ? getChildProductHandle(colour)
          : product.handle,
        images: isParentProduct
          ? product.variants.filter(variant => variant.option1 === colour)[0]
              .images
          : product.images,
        variants: product.variants.filter(
          variant => variant.option1 === colour,
        ),
        colour: colour,
        tags: product.tags.filter(
          tag =>
            (tag.includes(swatchColor) && tag.includes(colour)) ||
            tag.includes(messageTag) ||
            tag.includes(badgeTag),
        ),
        productLegacyResourceId: isParentProduct
        ? siblings.find(sibling => sibling.handle === getChildProductHandle(colour))?.productLegacyResourceId
        : product.id,
      }))
      .filter(filterAvailableProducts)

    // const defaultProductHandle = isParentProduct
    //   ? swatchesOrderList.length > 0
    //     ? products.find(product => product.colour === swatchesOrderList[0])?.handle
    //     : products[0]?.handle || product.handle
    //   : products[0]?.handle

    const defaultProductHandle = products[0]?.handle

    const [activeProductHandle, setActiveProductHandle] =
      useState(defaultProductHandle)

    const [hoverActiveProductHandle, setHoverActiveProductHandle] =
      useState(defaultProductHandle)

    const productsHashTable = Object.fromEntries(
      products.map(product => [product.handle, product]),
    )

    // this is to handle shopify wrongly duplicate product related white screen issue
    const activeProduct = products.length > 0 ? productsHashTable[activeProductHandle] : product

    const hoverActiveProduct =
      products.length > 0
        ? productsHashTable[hoverActiveProductHandle]
        : product

    const parentProductHandle = isParentProduct ? product.handle : undefined

    const isGiftCard = product.handle === giftcardHandle

    const filteredImages = hoverActiveProduct?.images?.filter(
      image => image?.src && image.src.trim() !== "",
    )

    Component.displayName = name
    return (
      <Component
        defaultProductHandle={defaultProductHandle}
        activeProduct={{
          ...activeProduct,
          productLegacyResourceId: activeProduct?.productLegacyResourceId,
        }}
        hoverActiveProduct={hoverActiveProduct}
        filteredImages={filteredImages}
        url={
          isGiftCard
            ? `${routes.GIFTCARD}`
            : `${routes.PRODUCT}/${
                activeProductHandle || activeProduct?.handle
              }`
        }
        product={product}
        products={products}
        activeProductHandle={activeProductHandle}
        setActiveProductHandle={setActiveProductHandle}
        setHoverActiveProductHandle={setHoverActiveProductHandle}
        swatchesOrderList={swatchesOrderList}
        parentProductHandle={parentProductHandle}
        hideQuickView={isGiftCard || hideQuickView}
        isGiftCard={isGiftCard}
        isSizeSoldOut={!!soldOutVariant}
        soldOutSize={soldOutVariant?.option2}
        collectionsObjForBreadCrumbs={collectionsObjForBreadCrumbs}
        layout={layout}
        price={price}
        isPriceLoaded={isPriceLoaded}
      />
    )
  }
